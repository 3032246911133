import { isProductionEnvironment } from '@login/utils/isProduction';
import Image from 'next/image';
import Script from 'next/script';

const GoogleTagManagerScript = () => {
  if (!isProductionEnvironment()) {
    return null;
  }

  return (
    <Script id="google-tag-manager-script">{`(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-M82X5DK');`}</Script>
  );
};

const GoogleTagManagerNoScript = () => {
  if (!isProductionEnvironment()) {
    return null;
  }

  return (
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-M82X5DK"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};

const FacebookScript = () => {
  if (!isProductionEnvironment()) {
    return null;
  }

  return (
    <Script
      src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=1721324668024811&autoLogAppEvents=1"
      nonce="rxWew55H"
      async
      defer
    />
  );
};

const FacebookPixelScript = () => {
  if (!isProductionEnvironment()) {
    return null;
  }

  return (
    <>
      <Script id="facebook-pixel-script">{`!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod
      ? n.callMethod.apply(n, arguments)
      : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(
  window,
  document,
  'script',
  'https://connect.facebook.net/en_US/fbevents.js',
);
fbq('init', '741334783102615');
fbq('track', 'PageView');`}</Script>
      <noscript>
        <Image
          alt=""
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=741334783102615&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  );
};

export {
  FacebookPixelScript,
  FacebookScript,
  GoogleTagManagerNoScript,
  GoogleTagManagerScript,
};
