import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Loader() {
  return (
    <div className="relative">
      <div className="flex justify-center items-center mt-12">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-primary-blue"
          size="2x"
          spin
        />
      </div>
    </div>
  );
}
