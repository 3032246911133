'use client';
import './globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import { TopProgressBar } from '@login/components/TopProgressBar';
import { NotificationProvider } from '@login/context/UseNotificationContext';
import {
  FacebookPixelScript,
  FacebookScript,
  GoogleTagManagerNoScript,
  GoogleTagManagerScript,
} from '@login/events/scripts';
import { Inter } from '@next/font/google';
import classNames from 'classnames';
import { Suspense } from 'react';

import Loading from './loading';

const inter = Inter({
  variable: '--font-inter',
  display: 'swap',
  subsets: ['latin'],
});

config.autoAddCss = false;
export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Suspense fallback={<Loading />}>
      <html lang="en" className={classNames(inter.className, 'bg-ice-grey-1')}>
        <head />
        <body>
          <GoogleTagManagerNoScript />
          <FacebookPixelScript />
          <TopProgressBar />
          <NotificationProvider>{children}</NotificationProvider>
          <GoogleTagManagerScript />
          <FacebookScript />
        </body>
      </html>
    </Suspense>
  );
}
