import React, { createContext, ReactNode, useContext, useState } from 'react';

import { NotificationTypes } from './types';

type NotificationType = {
  message: string;
  type: NotificationTypes;
  isGlobalTrigger?: boolean;
};

type NotificationContextType = {
  notification: NotificationType | undefined;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (isOpen: boolean) => void;
  showNotification: (notification: NotificationType) => void;
};

// Define the default value for the context
const defaultValue: NotificationContextType = {
  notification: undefined,
  isNotificationOpen: false,
  setIsNotificationOpen: () => {
    throw new Error('setIsNotificationOpen function must be overridden');
  },
  showNotification: () => {
    throw new Error('showNotification function must be overridden');
  },
};

export const NotificationContext =
  createContext<NotificationContextType>(defaultValue);

type NotificationProviderProps = {
  children: ReactNode;
};

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notification, setNotification] = useState<
    NotificationType | undefined
  >();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const showNotification = (notification: NotificationType) => {
    setNotification(notification);
    setTimeout(() => {
      setIsNotificationOpen(true);
    }, 300);

    if (notification.type === 'success') {
      setTimeout(() => {
        setIsNotificationOpen(false);
      }, 4500);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        notification,
        isNotificationOpen,
        setIsNotificationOpen,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
